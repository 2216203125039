const groupRouter = {
  path: '/lcproduct',
  component: () => import(/* webpackChunkName: "lcproduct-view-index" */ '../views/index.vue'),
  children: [

    // 酒店管理-无权限提示

    {
      path: 'noAccess',
      name: 'noAccess',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/hotel-manage/no-access.vue'),
    },
    {
      path: 'home-index',
      name: 'group-hotelIndex',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/hotel-manage/customer-index.vue'),
    },
    // 酒店管理

    {
      path: 'customer-index',
      name: 'group-hotelIndex',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/hotel-manage/customer-index.vue'),
    },
    {
      path: 'importHotel',
      name: 'importHotel',
      meta: {
        permissionCode: 'cp-hotelManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/hotel-manage/add-hotel.vue'),

    },


    // 品牌管理
    {
      path: 'brandManage',
      name: 'brandManage',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/brand-manage/list/index.vue'),
    },
    // 品牌动态列表
    {
      path: 'brandMicroblogList',
      name: 'brandMicroblogList',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/brand-manage/brand-microblog-list.vue'),
    },
    // 品牌动态管理
    {
      path: 'brandMicroblogManage',
      name: 'brandMicroblogManage',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/brand-manage/edit-brand-microblog.vue'),
    },
    // 品牌旗舰店
    {
      path: 'brandDetail',
      name: 'brandDetail',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/brand-manage/edit-brand-detail.vue'),
    },
    {
      path: 'tagManage',
      name: 'tagManage',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/tag-manage/tag-list.vue'),
    },
    {
      path: 'hotelBindTag',
      name: 'hotelBindTag',
      meta: {
        permissionCode: 'cp-group-brandManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/tag-manage/hotel-list.vue'),

    },
    // 酒店攻略
    {
      path: 'hotelStrategy/index',
      name: 'hotelStrategyIndex',
      meta: {
        permissionCode: 'cp-group-hotelStrategy',
        routerType: 2,
        title: '酒店攻略首页',
      },
      component: () => import('../views/group-management/hotelStrategy/index.vue'),
    },
    {
      path: 'hotelStrategy/editStrategy',
      name: 'editStrategy',
      meta: {
        permissionCode: 'cp-group-hotelStrategy',
        routerType: 2,
        title: '酒店攻略首页',
      },
      component: () => import('../views/group-management/hotelStrategy/children/editHotelStratety.vue'),

    },
    // 城市攻略
    {
      path: 'cityStrategy/index',
      name: 'cityStrategyIndex',
      meta: {
        permissionCode: 'cp-group-hotelStrategy',
        routerType: 2,
        title: '城市攻略攻略首页',
      },
      component: () => import('../views/group-management/cityStrategy/index.vue'),
    },
    // 城市攻略
    {
      path: 'cityStrategy/addCityStrategy',
      name: 'addCityStrategy',
      meta: {
        permissionCode: 'cp-group-hotelStrategy',
        routerType: 2,
        title: '城市攻略攻略首页',
      },
      component: () => import('../views/group-management/cityStrategy/child/addCityStrategy.vue'),
    },
    // 酒店亮点
    {
      path: 'hotelLightspot/index',
      name: 'hotelLightspot',
      meta: {
        permissionCode: 'cp-group-hotelLightspot',
        routerType: 2,
        title: '酒店亮点首页',
      },
      component: () => import('../views/group-management/hotelLightspot/index.vue'),
    },
    {
      path: 'hotelLightspot/editLightspot',
      name: 'editLightspot',
      meta: {
        permissionCode: 'cp-group-hotelLightspot',
        routerType: 2,
        title: '酒店亮点编辑',
      },
      component: () => import('../views/group-management/hotelLightspot/children/editHotelLightspot.vue'),

    },
    // 集团商城
    {
      path: 'mallManagement',
      name: 'mallManagement',
      meta: {
        permissionCode: 'cp-group-groupShopping',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/mall-management.vue'),
    },
    { // 集团商城订单
      path: 'groupMallOrder',
      name: 'groupMallOrder',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
        deepth:1
      },
      component: () => import('../views/group-management/group-mall-management/group-mall-order/index.vue'),
    },
    { // 商城奖励金管理
      path: 'mallRewardManagement',
      name: 'mallRewardManagement',
      meta: {
        permissionCode: 'cp-group-groupMallRewardManagement',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/mall-reward-management/index.vue'),
    },
    { // 集团商城订单详情
      path: 'groupMallOrder/detail',
      name: 'groupMallOrderDetail',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/hotel-management/mall-order/mall-order-detail.vue'),
    },
    // 快速创建tapd
    {
      path: 'groupMallOrderFeedBack',
      name: 'groupMallOrderFeedBack',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/tapd/index.vue'),
    },
    // tapd详情
    {
      path: 'groupMallOrderFeedBackDetail',
      name: 'groupMallOrderFeedBackDetail',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/tapd-detail/index.vue'),
    },
    // tapd详情
    {
      path: 'groupMallOrderFeedBackEdit',
      name: 'groupMallOrderFeedBackEdit',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/tapd-update/index.vue'),
    },
    { // 集团商城积分订单详情
      path: 'groupMallPointDetail',
      name: 'groupMallPointDetail',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/group-mall-order/groupPointDetail.vue'),
    },
    { // 打包产品订单详情页
      path: 'packageProductDetail',
      name: 'packageProductDetail',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
        deepth:2
      },
      component: () => import('../views/group-management/group-mall-management/package-product/package-product-detail.vue'),
    },
    // 设置新活动

    {
      path: 'groupSetPromotion',
      name: 'groupSetPromotion',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/set-promotion.vue'),
    },
    // 新建预售券、限时抢
    {
      path: 'groupCreatPromotion',
      name: 'groupCreatPromotion',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creat-promotion.vue'),
    },
    // 预售券、限时抢详情
    {
      path: 'groupPromotionDetail',
      name: 'groupPromotionDetail',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/promotion-detail.vue'),
    },
    // 预售券、限时抢详情
    {
      path: 'groupPromotionEdit',
      name: 'groupPromotionEdit',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/promotion-edit.vue'),
    },
    // 子产品列表

    {
      path: 'groupSubProductList',
      name: 'groupSubProductList',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/sub-product-list.vue'),
    },
    //  新增或修改子产品

    {
      path: 'groupCreatSubProduct',
      name: 'groupCreatSubProduct',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creat-sub-product.vue'),
    },
    //  新增或修改组合产品

    {
      path: 'groupCreatCombinationProduct',
      name: 'groupCreatCombinationProduct',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creat-combination-product.vue'),
    },
    // 活动管理

    {
      path: 'groupPromotionList',
      name: 'groupPromotionList',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/promotion-list.vue'),
    },
    // 活动历史

    {
      path: 'groupPromotionHistory',
      name: 'groupPromotionHistory',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/promotion-history.vue'),
    },
    // 创建券包售卖产品

    {
      path: 'groupCreatCouponProduct',
      name: 'groupCreatCouponProduct',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creat-coupon-product.vue'),
    },
    // 券包售卖产品详情

    {
      path: 'groupCouponProductDetail',
      name: 'groupCouponProductDetail',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/coupon-product-detail'),
    },

    // 马甲管理

    {
      path: 'lcCtripMemberLevelMapping',
      name: 'lcCtripMemberLevelMapping',
      meta: {
        permissionCode: 'cp-group-ctripDistributionVestManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/vest-product/lc-ctrip-member-level-mapping'),
    },
    {
      path: 'hotelPriceSource',
      name: 'hotelPriceSource',
      meta: {
        permissionCode: 'cp-group-ctripDistributionVestManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/vest-product/hotel-price-source'),
    },
    {
      path: 'vestProductSwitch',
      name: 'vestProductSwitch',
      meta: {
        permissionCode: 'cp-group-ctripDistributionVestManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/vest-product/vest-product-switch.vue'),
    },
    {
      path: 'vestProductReport',
      name: 'vestProductReport',
      meta: {
        permissionCode: 'cp-group-ctripDistributionVestManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/vest-product/vest-product-report.vue'),
    },
    {
      path: 'ctripHotelMapping',
      name: 'ctrip-hotel-mapping',
      meta: {
        permissionCode: 'cp-group-ctripDistributionVestManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/vest-product/ctripHotelMapping.vue'),

    },
    {
      // 集团结算中心跳到商城订单详情
      path: 'settlement-order/settlement/groupMallOrderDetail',
      name: 'settlementGroupMallOrderDetail',
      meta: {
        permissionCode: 'cp-settlementCenter',
        routerType: 2,
      },
      component: () => import('../views/hotel-management/mall-order/mall-order-detail.vue'),
    },
    // 新建携程权益产品创建
    {
      path: 'groupCreatTripProduct',
      name: 'groupCreatTripProduct',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creatTripProduct/creat-tripProduct.vue'),
    },
    // 查看携程权益产品详情
    {
      path: 'groupTripProductDetail',
      name: 'groupTripProductDetail',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/creatTripProduct/productDetail.vue'),
    },
    // 创建&编辑打包产品
    {
      path: 'groupCreatPackProduct',
      name: 'groupCreatPackProduct',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/pack-product-management/edit-pack-product.vue'),
    },
    // 分销渠道管理
    {
      path: 'distributionList',
      name: 'distributionList',
      meta: {
        permissionCode: 'cp-group-distribution',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/distribution-manage/distribution-channel-list.vue'),
    },
    // 分销渠道产品管理
    {
      path: 'distributionManage',
      name: 'distributionManage',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/distribution-manage/distribution-product-list.vue'),
    },
    // 分销渠道创建
    {
      path: 'distributionEdit',
      name: 'distributionEdit',
      meta: {
        permissionCode: 'cp-group-distribution',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/distribution-manage/distribution-channel-edit.vue'),
    },
    // 白名单
    {
      path: 'groupPromotionWhiteList',
      name: 'groupPromotionWhiteList',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/promotion-whiteList.vue'),
    },
    // 联盟会员列表
    {
      path: 'unionMemberList',
      name: 'unionMemberList',
      meta: {
        permissionCode: 'cp-group-unionMemberList',
        routerType: 2,
      },
      component: () => import('../views/group-management/union-member-manage/union-member-list.vue'),
    },
    // 编辑联盟会员
    {
      path: 'editUnionMember',
      name: 'editUnionMember',
      meta: {
        permissionCode: 'cp-group-unionMemberList',
        routerType: 2,
      },
      component: () => import('../views/group-management/union-member-manage/union-member-edit.vue'),
    },
    // 编辑丽呈会员协议
    {
      path: 'rezenMember',
      name: 'rezenMember',
      meta: {
        permissionCode: 'cp-group-unionMemberList',
        routerType: 2,
      },
      component: () => import('../views/group-management/union-member-manage/rezen-member-edit.vue'),
    },
    {
      // 会员升级订单
      path: 'groupSaleGoldenCard',
      name: 'groupSaleGoldenCard',
      meta: {
        permissionCode: 'cp-orderManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/sale-card/sale-golden-card.vue'),
    },
    {
      path: 'memberCardDetail',
      name: 'memberCardDetail',
      meta: {
        permissionCode: 'cp-orderManage',
        routerType: 2,
      },
      component: () => import('../views/group-management/group-mall-management/sale-card/member-card-detail.vue'),
    },
    {
      path: 'checkPayinfo',
      name: 'checkPayinfo',
      meta: {
        permissionCode: 'cp-group-groupMallOrder',
        routerType: 2,
      },
      component: () => import('../views/group-management/check-pay-info/index.vue'),
    },
    {
      path: 'unionMemberSystem',
      name: 'unionMemberSystem',
      meta: {
        permissionCode: 'cp-group-unionMemberList',
        routerType: 2,
      },
      component: () => import('../views/group-management/union-member-system/index.vue'),
    },
    {
      path: 'unionMemberSystem/edit',
      name: 'unionMemberSystemEdit',
      meta: {
        permissionCode: 'cp-group-unionMemberList',
        routerType: 2,
      },
      component: () => import('../views/group-management/union-member-system/edit.vue'),
    },
    {
      path: 'memberLevelTemplate',
      name: 'memberLevelTemplate',
      meta: {
        permissionCode: 'cp-group-setPromotion',
        routerType: 2,
      },
      component: () => import('../views/group-management/member-level-template/index'),
    },
  ],
};
export default groupRouter;
